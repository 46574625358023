import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import closeIcon from '../../../public/icons/close-icon.svg';

import headerStyles from './PublicHeader.module.scss';

interface MobileMenuProps {
  showMenu: boolean;
  toggleMobileMenu: () => void;
  isAuthenticated: boolean;
  logoutme: () => void;
  handleMobileSignUpClick: () => void;
  handleMobileLoginClick: () => void;
}

export function MobileMenuView({
  showMenu,
  toggleMobileMenu,
  isAuthenticated,
  logoutme,
  handleMobileSignUpClick,
  handleMobileLoginClick
}: MobileMenuProps) {
  return (
    <div className={classNames(headerStyles.mmenu, { [headerStyles.show]: showMenu })}>
      <img
        onClick={() => toggleMobileMenu()}
        src={closeIcon.src}
        className={headerStyles.closeMenuIcon}
        role="img"
        alt="Close menu"
      />
      {isAuthenticated && (
        <>
          <Link href="/dashboard">
            <a onClick={() => toggleMobileMenu()}>Your Offers</a>
          </Link>

          <Link href="/dashboard/saved-opportunities">
            <a onClick={() => toggleMobileMenu()}>Saved Opportunities</a>
          </Link>
        </>
      )}
      <a
        className="dark"
        target="_blank"
        href="https://sundae.com/investor/pricing/"
        rel="noopener noreferrer"
        onClick={() => toggleMobileMenu()}
      >
        <span>Pricing</span>
      </a>
      <a
        onClick={() => toggleMobileMenu()}
        className="dark"
        target="_blank"
        href="https://sundae.com/blog/investor/"
        rel="noopener noreferrer"
      >
        <span>Investor Blog</span>
      </a>
      <a
        className="dark"
        target="_blank"
        href="https://sundae.com/investors/faq/"
        rel="noopener noreferrer"
      >
        <span>FAQ</span>
      </a>

      {!isAuthenticated && (
        <>
          <a
            onClick={() => {
              handleMobileSignUpClick();
            }}
            className={classNames('btn', headerStyles.btnPink, headerStyles.btnTFix, 'mt-5')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Join for Free</span>
          </a>

          <a
            onClick={() => handleMobileLoginClick()}
            href="/#login"
            className={classNames('mt-3', 'btn', headerStyles.btnTFix, headerStyles.btnPinkOutline)}
          >
            Login
          </a>
        </>
      )}
      {isAuthenticated && (
        <>
          <Link href="/dashboard/account-settings">
            <a className="dark mt-3" onClick={() => toggleMobileMenu()}>
              Account Settings
            </a>
          </Link>
          <Link href="/dashboard/buy-box">
            <a className="dark" onClick={() => toggleMobileMenu()}>
              Buy Box
            </a>
          </Link>
          <a
            className="dark"
            onClick={() => {
              toggleMobileMenu();
              logoutme();
            }}
          >
            Logout
          </a>
        </>
      )}
      {/* We may reuse this exact IA call now section elsewhere */}
      {/* {person && (
        <div className={headerStyles.mobileIaSection}>
          <>
            <CellPhoneIcon className={headerStyles.phoneIcon} />
            <div className={headerStyles.iAInfo}>
              <span>
                <strong>{person.name}</strong>
                <br /> Investor Advisor
              </span>
              <div className={headerStyles.callButton}>
                <a href={`tel:${person.phoneNo}`}>Call Now</a>
              </div>
            </div>
          </>
        </div>
      )} */}
    </div>
  );
}
