import { useEffect } from 'react';

export function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, hideDropDown: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const curRef = ref.current;

      if (event.target instanceof HTMLElement && !curRef?.contains(event.target)) {
        hideDropDown();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
