import React from 'react';

import { HelpScout } from '../../components/HelpScout';

const InvestorContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <HelpScout />
      {children}
    </>
  );
};

export default InvestorContainer;
