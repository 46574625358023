// eslint-disable no-sequences

import { useEffect } from 'react';

import { getUserInfo } from '../../utils/localStorage.utils';

const HELP_SCOUT_ID = '4d837b82-419a-4019-99bf-c72b9347130c';
const HELP_SCOUT_MESSAGE_ID = 'bd9b073c-b3b6-4df4-a4be-f64b7193ef23';

const registerHelpScoutBeacon = () => {
  if (!(window as any).Beacon) {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    !(function (e, t, n) {
      function a() {
        const e = t.getElementsByTagName('script')[0];
        const n = t.createElement('script');
        /* eslint-disable */
        (n.type = 'text/javascript'),
          (n.async = !0),
          (n.src = 'https://beacon-v2.helpscout.net'),
          e?.parentNode?.insertBefore(n, e);
      }

      /* eslint-enable */
      if (
        ((e.Beacon = n =
          function (t: any, n: any, a: any) {
            e?.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        t.readyState === 'complete')
      )
        return a();
      e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
    })(
      window as any,
      document,
      (window as any).Beacon ||
        function () {
          //
        }
    );

    (window as any).Beacon('init', HELP_SCOUT_ID);
  }
};

export const HelpScout = () => {
  const userInfo = getUserInfo();

  useEffect(() => {
    registerHelpScoutBeacon();

    if (userInfo?.email) {
      (window as any).Beacon('identify', {
        email: userInfo.email
      });
    }

    if (userInfo?.id) {
      (window as any).Beacon('show-message', HELP_SCOUT_MESSAGE_ID);
    }
  }, [userInfo?.email, userInfo?.id]);

  return null;
};
