import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { getUserInfo } from '../../api/user/getUserInfo';
import { getInitials } from '../../utils/common.utils';

import styles from './UserAvatar.module.scss';
import { UserAvatarProps } from './UserAvatarTypes';

type UserProfileAvatarRequiredType = {
  firstName: string | null;
  lastName: string | null;
  name?: string | null;
};

const UserAvatar = ({
  className,
  onClick,
  profileInfo,
  profileInfoOnly
}: UserAvatarProps): React.ReactElement => {
  const [userData, setUserData] = useState<UserProfileAvatarRequiredType>();

  useEffect(() => {
    const getData = async () => {
      const user = await getUserInfo();
      setUserData(user);
    };

    if (profileInfo) {
      setUserData(profileInfo);
    } else if (!profileInfoOnly) {
      getData();
    }
  }, [profileInfo, profileInfoOnly]);

  const Initials = useMemo(() => {
    const name = userData?.name
      ? userData?.name
      : userData?.firstName || userData?.lastName
      ? `${userData?.firstName} ${userData?.lastName}`
      : '';

    return getInitials(name);
  }, [userData?.firstName, userData?.lastName, userData?.name]);

  return (
    <>
      <div className={classNames(styles.userAvatar, className)} onClick={onClick} role="menu">
        <div className={styles.innerAvatar}>{Initials}</div>
      </div>
    </>
  );
};

export default UserAvatar;
