import React, { createContext, useContext, useEffect, useState } from 'react';

const ViewPortContext = createContext<UseViewport>({
  width: 0,
  height: 0,
  isMobile: false,
  isTablet: false
});

interface UseViewport {
  width?: number;
  height?: number;
  isMobile: boolean;
  isTablet: boolean;
}

export const ViewportProvider = ({
  children
}: React.HTMLAttributes<HTMLElement>): React.ReactElement => {
  const tabletBreakPoint = 1000;
  const mobileBreakPoint = 650;

  const [width, setWidth] = useState(1366);
  const [height, setHeight] = useState(768);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsMobile(window.innerWidth <= mobileBreakPoint);
    setIsTablet(window.innerWidth <= tabletBreakPoint);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setIsMobile(window.innerWidth <= mobileBreakPoint);
      setIsTablet(window.innerWidth <= tabletBreakPoint);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const value: UseViewport = {
    width,
    height,
    isMobile,
    isTablet
  };

  return <ViewPortContext.Provider value={value}>{children}</ViewPortContext.Provider>;
};

export const useViewportContext = (): UseViewport => useContext(ViewPortContext);
