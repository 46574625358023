import classNames from 'classnames';
import Link from 'next/link';
import React, { useRef, useState } from 'react';

import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import { ExpandIcon } from '../../Icons';
import UserAvatar from '../../UserAvatar/UserAvatar';

import styles from './UserMenu.module.scss';
import { UserMenuControlProp } from './UserMenuTypes';

const UserMenuDropDown = ({ setIsOpen, logoutme, showHandler }: UserMenuControlProp) => {
  const closeMenu = () => {
    setIsOpen(false);
    if (showHandler) showHandler(false);
  };

  return (
    <div className={styles.dropDown}>
      <Link href="/dashboard">
        <a
          onClick={closeMenu}
          className={classNames({
            [styles.active]: window.location.pathname === '/dashboard'
          })}
        >
          Your Offers
        </a>
      </Link>

      <Link href="/dashboard/saved-opportunities">
        <a
          onClick={closeMenu}
          className={classNames({
            [styles.active]: window.location.pathname === '/dashboard/saved-opportunities'
          })}
        >
          Saved Opportunities
        </a>
      </Link>
      <Link href="/dashboard/account-settings">
        <a
          className={classNames(styles.accountArea, {
            [styles.active]: window.location.pathname === '/dashboard/account-settings'
          })}
          onClick={closeMenu}
        >
          Account Settings
        </a>
      </Link>
      <Link href="/dashboard/buy-box">
        <a
          className={classNames({
            [styles.active]: window.location.pathname === '/dashboard/buy-box'
          })}
          onClick={closeMenu}
        >
          Buy Box
        </a>
      </Link>

      <a
        href=""
        onClick={() => {
          closeMenu();
          logoutme();
        }}
      >
        Logout
      </a>
    </div>
  );
};

export const UserMenu = ({ setIsOpen, logoutme, isAuthenticated }: UserMenuControlProp) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const showHandler = (showDrop: boolean) => {
    setShowDropDown(showDrop);
  };

  const hideDropDown = () => {
    setShowDropDown(false);
  };

  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(menuRef, hideDropDown);

  return (
    <div ref={menuRef} className={styles.userMenuWrapper}>
      <div onClick={() => setShowDropDown(!showDropDown)}>
        <UserAvatar className={styles.userMenuIcon} />
        <ExpandIcon
          className={classNames(styles.expandIcon, { [styles.collapsedIcon]: showDropDown })}
        />
      </div>
      {showDropDown && (
        <UserMenuDropDown
          setIsOpen={setIsOpen}
          isAuthenticated={isAuthenticated}
          showHandler={showHandler}
          logoutme={logoutme}
        />
      )}
    </div>
  );
};
