import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavbarToggler } from 'reactstrap';

import logoIcon from '../../../public/logo.svg';
import { ViewportProvider } from '../../contexts/ViewportProvider';
import { useModalByName } from '../../hooks/useModalByName';
import { AppDispatch } from '../../store';
import { selectMenuOpen } from '../../store/mobileMenu';
import { clearLastRoute, logout } from '../../utils/localStorage.utils';
import { HamburgerIcon } from '../Icons';
import { UserMenu } from '../UI/UserMenu/UserMenu';

import { MobileMenuView } from './MobileMenu';
import headerStyles from './PublicHeader.module.scss';

export interface PublicHeaderProps {
  isAuthenticated: boolean;
  isLeftNavHidden?: boolean;
}

export const LENDING_APP_FRONTEND_URL =
  process.env.NEXT_PUBLIC_LENDING_APP_FRONTEND_URL || 'http://development.lending-app.dev.sundae/';

export const PublicHeader = ({ isAuthenticated, isLeftNavHidden }: PublicHeaderProps) => {
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState(router.pathname.replace(/\//g, ''));
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const showMenu = useSelector(selectMenuOpen);
  const [, showSignUpModal] = useModalByName('SignUp');
  const [, showLoginModal] = useModalByName('LoginModal');

  const isOpenHandler = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    const { pathname, query } = router;
    const splitLocation = pathname.split('/');
    setSelectedMenu(
      splitLocation[1] === 'feed' || !splitLocation[1] ? 'marketplace' : splitLocation[1]
    );
    const nextPath = query.next as string;

    if (isAuthenticated && nextPath) {
      router.push(nextPath);
    }
  }, [router, isAuthenticated]);

  const toggle = () => setIsOpen(!isOpen);

  const logoutme = () => {
    clearLastRoute();
    logout();
    router.reload();
  };

  const toggleMobileMenu = () => {
    dispatch({ type: 'TOGGLE_MENU' });
  };

  const hideMobileMenu = () => {
    dispatch({ type: 'CLOSE_MENU' });
  };

  const handleMobileSignUpClick = () => {
    toggleMobileMenu();
    showSignUpModal();
  };

  const handleMobileLoginClick = () => {
    toggleMobileMenu();
    showLoginModal();
  };

  return (
    <ViewportProvider>
      <div
        id="navBarTop"
        className={classNames(headerStyles.navbarTop, !isAuthenticated ? headerStyles.fixed : '')}
      >
        <div>
          <Link href="/">
            <a className={headerStyles.navBarBrand} onClick={hideMobileMenu}>
              <img alt="Sundae" src={logoIcon.src} />{' '}
            </a>
          </Link>
        </div>

        <div className={headerStyles.mobileRightNav}>
          <div className={headerStyles.mobileNav} onClick={toggleMobileMenu}>
            <HamburgerIcon className={headerStyles.hamburgerIcon} />
          </div>
        </div>

        <MobileMenuView
          toggleMobileMenu={toggleMobileMenu}
          isAuthenticated={isAuthenticated}
          showMenu={showMenu}
          handleMobileSignUpClick={handleMobileSignUpClick}
          logoutme={logoutme}
          handleMobileLoginClick={handleMobileLoginClick}
        />

        <NavbarToggler onClick={toggle} className={headerStyles.navBarToggler} />
        <div className={headerStyles.menuSection}>
          <ul
            className={classNames(headerStyles.leftNav, { [headerStyles.hide]: isLeftNavHidden })}
          >
            <li
              className={
                selectedMenu === 'marketplace' || selectedMenu === 'dashboard'
                  ? headerStyles.active
                  : ''
              }
              onClick={() => setIsOpen(false)}
            >
              <a href="/">
                <span>Marketplace</span>
              </a>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <a
                className="dark"
                target="_blank"
                href="https://sundae.com/investor/pricing/"
                rel="noopener noreferrer"
              >
                <span>Pricing</span>
              </a>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <a
                className="dark"
                target="_blank"
                href="https://sundae.com/blog/investor/"
                rel="noopener noreferrer"
              >
                <span>Investor Blog</span>
              </a>
            </li>
            <li
              className={selectedMenu === 'faq' ? headerStyles.active : ''}
              onClick={() => setIsOpen(false)}
            >
              <a
                className="dark"
                target="_blank"
                href="https://sundae.com/investors/faq/"
                rel="noopener noreferrer"
              >
                <span>FAQ</span>
              </a>
            </li>
          </ul>
          <div className={headerStyles.rightNav}>
            {!isAuthenticated && (
              <>
                <div className={classNames('noborder', headerStyles.joinfree)}>
                  <a href="tel:1-833-833-0042">1-833-833-0042</a>
                </div>
                <div
                  onClick={() => showSignUpModal()}
                  className={classNames('noborder', headerStyles.joinfree)}
                >
                  <span>Join for free</span>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(false);
                    showLoginModal();
                  }}
                  className={headerStyles.loginHeaderBtn}
                >
                  Login
                </div>
              </>
            )}

            {isAuthenticated && (
              <UserMenu
                isAuthenticated={isAuthenticated}
                setIsOpen={isOpenHandler}
                logoutme={logoutme}
              />
            )}
          </div>
        </div>
      </div>
    </ViewportProvider>
  );
};
